
let start = new Date(new Date().getFullYear(), 0, 1),
    end = new Date();

export function getPeriodo() {
    return { start, end };
}

export function setPeriodo(obj) {
    start = obj.start;
    end = obj.end;
}

export function getStartDate() {
    return start;
}

export function getEndDate() {
    return end;
}

export function setStartDate(date) {
    start = date;
}

export function setEndDate(date) {
    end = date;
}