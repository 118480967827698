import React, { PureComponent } from 'react';
import { authContext } from '../authContext';
import { formatter, dateToString, getExportData } from '../utils';
import { listaProveedores } from '../api/proveedores';
import { downloadCompra } from '../api/files';
import { compras } from '../api/compras';
import Periodo from '../components/Periodo';
import { getPeriodo, getStartDate, getEndDate } from '../periodo';
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


export default class Compras extends PureComponent {
    static displayName = Compras.name;
    static contextType = authContext;

    constructor(props) {
        super(props);
        this.state = {
            proveedores: null,
            compras: null,
            punteo: null
        }

        this.props.setLoader(true);
        this.setListaProveedores();
    }

    setListaProveedores = () => {
        listaProveedores().then(res => {
            if (res.ok) return res.json();
            else throw res.status;
        }).then(({ value }) => {
            this.setState({ proveedores: value });
            this.props.setLoader(false);
        }).catch(e => {
            if (e === 401) {
                this.context.logout();
            } else {
                this.setState({ error: true });
                this.props.setLoader(false);
            }
        });
    }

    loadProveedor = (event, punteo) => {
        if (event) {
            event.persist();
            const NombreComercial = event.target.value;
            this.proveedor = this.state.proveedores.find(proveedor => proveedor.NombreComercial.trim() === NombreComercial);
        }

        punteo = typeof punteo === 'undefined' ? this.state.punteo : punteo;

        if (this.proveedor?.CtaProveedor) {
            this.props.setLoader(true);
            this.saldo = 0;
            this.debe = 0;
            this.haber = 0;

            compras(this.proveedor.CtaProveedor, getPeriodo(), punteo).then(res => {
                if (res.ok) return res.json();
                else throw res.status;
            }).then(({ value }) => {
                const data = this.getDataToExport(value);
                this.setState({ compras: value, data, punteo, error: false });
                this.props.setLoader(false);
            }).catch(e => {
                if (e === 401) {
                    this.context.logout();
                } else {
                    this.setState({ error: true });
                    this.props.setLoader(false);
                }
            });
        }
    }

    reset = () => {
        this.inputProveedor && (this.inputProveedor.value = '');
        this.proveedor = null;
        this.setState({ compras: null, error: false });
    }

    downloadFile = (idAsiento, tipo, concepto) => {
        this.props.setLoader(true);
        downloadCompra(idAsiento, tipo).then(res => {
            if (res.ok) return res.blob();
            else throw res.status;
        }).then(blob => {
            if (blob && navigator?.msSaveBlob) {
                navigator.msSaveBlob(blob, concepto + '.pdf');
                this.props.setLoader(false);
                return;
            }
            const elm = document.createElement('a');
            elm.href = URL.createObjectURL(blob);
            elm.setAttribute('download', concepto + '.pdf');
            document.body.append(elm);
            elm.click();
            elm.remove();
            URL.revokeObjectURL(elm.href);
            this.props.setLoader(false);
        }).catch(e => {
            if (e === 401) {
                this.context.logout();
            } else {
                this.setState({ error: true });
                this.props.setLoader(false);
            }
        });
    }

    dropdownPunteoHandler = isHoverable => {
        if (isHoverable) {
            this.dropdownPunteo && (this.dropdownPunteo.className = 'dropdown is-active');
        } else {
            this.dropdownPunteo && (this.dropdownPunteo.className = 'dropdown');
        }
    }

    onClickPunteoHandler = punteo => {
        this.dropdownPunteoHandler(false);
        this.proveedor && this.loadProveedor(null, punteo);
    }


    getDataToExport = compras => {
        const details = [];
        details.push({
            label: 'Proveedor',
            description: this.proveedor.NombreComercial.trim(),
            code: this.proveedor.Proveedor.toString()
        });
        details.push({
            label: 'Periodo',
            start: getStartDate(),
            end: getEndDate()
        });
        const dataColumns = ['FECHA', 'ASIENTO', 'CONCEPTO', 'PAGO', 'COMPRA', 'SALDO'];
        let debe = 0, haber = 0, saldo = 0;
        const data = compras.map((row, index) => {
            debe += row.Tipo === 'D' ? row.Importe : 0;
            haber += row.Tipo === 'H' ? row.Importe : 0;
            saldo = row.Tipo === 'D' ? saldo - row.Importe : saldo + row.Importe;
            let backgroundColor = 'FFFFFF';
            if (index % 2 !== 0) backgroundColor = 'e6e6e6';
            return [
                { value: dateToString(row.Fecha), style: { fill: { patternType: "solid", fgColor: { rgb: backgroundColor } } } },
                { value: row.Asiento.toString(), style: { fill: { patternType: "solid", fgColor: { rgb: backgroundColor } }, alignment: { horizontal: 'right' } } },
                { value: row.Concepto.trim(), style: { fill: { patternType: "solid", fgColor: { rgb: backgroundColor } } } },
                { value: row.Tipo === 'D' ? formatter(row.Importe) : '', style: { fill: { patternType: "solid", fgColor: { rgb: backgroundColor } }, alignment: { horizontal: 'right' } } },
                { value: row.Tipo === 'H' ? formatter(row.Importe) : '', style: { fill: { patternType: "solid", fgColor: { rgb: backgroundColor } }, alignment: { horizontal: 'right' } } },
                { value: formatter(saldo), style: { fill: { patternType: "solid", fgColor: { rgb: backgroundColor } }, alignment: { horizontal: 'right' } } },
            ]
        });
        data.push([
            { value: 'TOTALES', style: { font: { bold: true }, fill: { patternType: "solid", fgColor: { rgb: "808080" } } } },
            { value: '', style: { font: { bold: true }, fill: { patternType: "solid", fgColor: { rgb: "808080" } } } },
            { value: '', style: { font: { bold: true }, fill: { patternType: "solid", fgColor: { rgb: "808080" } } } },
            { value: formatter(debe), style: { font: { bold: true }, fill: { patternType: "solid", fgColor: { rgb: "808080" } }, alignment: { horizontal: 'right' } } },
            { value: formatter(haber), style: { font: { bold: true }, fill: { patternType: "solid", fgColor: { rgb: "808080" } }, alignment: { horizontal: 'right' } } },
            { value: formatter(saldo), style: { font: { bold: true }, fill: { patternType: "solid", fgColor: { rgb: "808080" } }, alignment: { horizontal: 'right' } } },
        ]);

        return getExportData(this.context.user.name.trim(), 'RESUMEN DE COMPRAS', details, dataColumns, data);
    }



    render() {
        if (this.state.proveedores == null) {
            return this.state.error ? <div className="box has-background-danger-light has-text-danger">
                Ha ocurrido un error inesperado. Vuelva a intentarlo más tarde.
            </div> : null;
        }
        if (this.state.proveedores?.length === 0) {
            return <div className="box has-background-warning-light has-text-warning">
                No se han encontrado proveedores.
            </div>;
        }

        return (<>
            {this.state.error && <div className="box has-background-danger-light has-text-danger mb-0">
                Ha ocurrido un error inesperado. Vuelva a intentarlo más tarde.
            </div>}
            <div className="section">
                <div className="container">
                    <div className="nombreEmpresa fontTitle">{this.context.user.name.trim()}</div>
                    <div>
                        Proveedor
                        <div className="is-flex-desktop is-flex-grow-1 is-flex-direction-row is-align-items-center">
                            <div className="is-flex is-flex-grow-1 is-flex-direction-row is-align-items-center mb-3">
                                <input list="proveedores" ref={ref => this.inputProveedor = ref} className="input mr-4-desktop" name="proveedores" type="text" onChange={this.loadProveedor} placeholder="Escriba o seleccione un proveedor" />
                                {this.proveedor != null && <a title="Reestablecer selección" className="delete ml-3-mobile mr-5-desktop" onClick={this.reset}></a>}
                            </div>
                            <div className="is-flex is-justify-content-center mb-3">
                                <Periodo onChange={this.loadProveedor} />
                            </div>
                        </div>
                        <datalist id="proveedores">
                            {this.state.proveedores.map(proveedor =>
                                <option key={proveedor.Proveedor} value={proveedor.NombreComercial.trim()} />
                            )}
                        </datalist>
                    </div>
                    <div className="is-flex-desktop is-flex-direction-row is-align-items-center">
                        <div className="is-flex-touch is-align-items-center is-justify-content-center ">
                            <div className="dropdown" ref={ref => this.dropdownPunteo = ref} onMouseOut={this.dropdownPunteoHandler.bind(this, false)} onMouseOver={this.dropdownPunteoHandler.bind(this, true)}>
                                <div className="dropdown-trigger minWidth240">
                                    <button className="button" aria-haspopup="true" aria-controls="dropdown-menu">
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tag" viewBox="0 0 16 16">
                                                <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
                                                <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586l7 7L13.586 9l-7-7H2v4.586z" />
                                            </svg>
                                        </span>
                                        <span>Punteo: {this.state.punteo == null ? 'Todos' : this.state.punteo === false ? 'No Punteados' : 'Punteados'}</span>
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                                <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659l4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                    <div className="dropdown-content">
                                        <a className={this.state.punteo == null ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.onClickPunteoHandler.bind(this, null)}>Todos</a>
                                        <a className={this.state.punteo === false ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.onClickPunteoHandler.bind(this, false)}>No Punteados</a>
                                        <a className={this.state.punteo === true ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.onClickPunteoHandler.bind(this, true)}>Punteados</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.compras != null &&
                            <div className="is-flex is-flex-grow-1 is-flex-direction-row is-align-items-center mt-3 mt-0-desktop">
                                <div className="is-flex is-flex-grow-1 is-justify-content-center mt-2-desktop">{this.proveedor != null ? 'Código Proveedor: ' + this.proveedor.Proveedor : ''}</div>
                                    <div className="is-flex is-justify-content-flex-end minWidth240">
                                    <ExcelFile filename="compras" element={
                                            <button className="button" disabled={this.state.compras.length === 0} title="Descargar datos en formato Excel">
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
                                                        <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z" />
                                                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                                    </svg>
                                                </span>
                                                <span>Exportar</span>
                                            </button>
                                        }>
                                            <ExcelSheet dataSet={this.state.data} name="Compras">
                                            </ExcelSheet>
                                        </ExcelFile>
                                </div>
                            </div>
                        }
                    </div>
                    {this.state.compras != null &&
                        <div className="table-container mt-3">
                            <table id="table" className="table is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>FECHA</th>
                                        <th align="center">ASIENTO</th>
                                        <th align="center" className="noExport">DOC</th>
                                        <th>CONCEPTO</th>
                                        <th align="center">PAGO</th>
                                        <th align="center">COMPRA</th>
                                        <th align="center">SALDO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.compras.length === 0 ? <tr className="noHover"><td colSpan="7" className="text-nowrap">No hay datos.</td></tr>
                                    :
                                    this.state.compras.map(row => {
                                        this.debe += row.Tipo === 'D' ? row.Importe : 0;
                                        this.haber += row.Tipo === 'H' ? row.Importe : 0;
                                        this.saldo = row.Tipo === 'D' ? this.saldo - row.Importe : this.saldo + row.Importe;

                                        return <tr key={row.IdAsiento}>
                                            <td>{dateToString(row.Fecha)}</td>
                                            <td align="end">{row.Asiento}</td>
                                            {row.Estado === 10 ?
                                                <td align="center" className="download noExport" onClick={this.downloadFile.bind(this, row.IdAsiento, row.Tipo, row.Concepto.trim())} title="Descargar documento">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16" >
                                                        <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z" />
                                                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                                    </svg>
                                                </td> :
                                                <td align="center" className="noExport" title="No existe documento a descargar">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                </td>
                                            }
                                            <td align="start">{row.Concepto.trim()}</td>
                                            <td align="end">{row.Tipo === 'D' && formatter(row.Importe)}</td>
                                            <td align="end">{row.Tipo === 'H' && formatter(row.Importe)}</td>
                                            <td align="end">{formatter(this.saldo)}</td>
                                        </tr>
                                    })}
                                    {this.state.compras.length !== 0 &&
                                        <tr className="noHover noExport"><td colSpan="7"></td></tr>
                                    }
                                    <tr className="final noHover">
                                        <td>TOTALES</td>
                                        <td></td>
                                        <td className="noExport"></td>
                                        <td></td>
                                        <td align="end">{formatter(this.debe)}</td>
                                        <td align="end">{formatter(this.haber)}</td>
                                        <td align="end">{formatter(this.saldo)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </>);
    }
}
