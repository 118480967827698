import { URL_PREFIX } from './data';

export function previsionCobros(banco, periodo) {
    let query = '';

    if (banco) {
        query += query ? " and CtaCobro eq '" + banco + "'" : "CtaCobro eq '" + banco + "'";
    }
    if (periodo?.start) {
        query += query ? ' and Fecha ge ' + periodo.start.toISOString() : 'Fecha ge ' + periodo.start.toISOString();
    }
    if (periodo?.end) {
        query += query ? ' and Fecha lt ' + periodo.end.toISOString() : 'Fecha lt ' + periodo.end.toISOString();
    }

    return fetch(URL_PREFIX + 'VSPrevisionCobros?$orderby=Fecha asc&' + (query ? '$filter=' + query : ''));
}