import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ProvideAuth } from './authContext';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <ProvideAuth>
      <BrowserRouter basename={baseUrl} >
        <App />
      </BrowserRouter>
   </ProvideAuth>,
  rootElement);

registerServiceWorker();

