import { URL_PREFIX } from './data';

export function documentos(tipo, periodo, cadena) {
    let query = '';

    if (tipo && tipo !== '') {
        query += query ? " and Tipo eq '" + tipo + "'" : "Tipo eq '" + tipo + "'";
    }
    if (periodo?.start) {
        query += query ? ' and FechaRegistro ge ' + periodo.start.toISOString() : 'FechaRegistro ge ' + periodo.start.toISOString();
    }
    if (periodo?.end) {
        query += query ? ' and FechaRegistro lt ' + periodo.end.toISOString() : 'FechaRegistro lt ' + periodo.end.toISOString();
    }
    if (cadena && cadena !== '') {
        query += query ? " and indexof(Descripcion, '" + cadena + "') gt - 1" : "indexof(Descripcion, '" + cadena + "') gt - 1";
    } 

    return fetch(URL_PREFIX + 'VSWebDocumentos?$orderby=FechaRegistro asc&' + (query ? '$filter=' + query : ''));
}


export function listaTipos() {
    return fetch(URL_PREFIX + 'VSWebDocumentosTipos');
}
