import React, { PureComponent } from 'react';
import { authContext } from '../authContext';
import { formatter } from '../utils';
import { resumenIngresos, resumenGastos } from '../api/resumen';
import { getEndDate } from '../periodo';


export default class Resumen extends PureComponent {
    static displayName = Resumen.name;
    static contextType = authContext;

    constructor(props) {
        super(props);
        this.state = {
            year: getEndDate().getFullYear(),
            ingresos: [],
            gastos: []
        }

        this.loadData(this.state.year);
    }

    loadData = year => {
        this.props.setLoader(true);
        this.dropdownYearHandler(false);

        const getIngresos = resumenIngresos(year).then(res => {
            if (res.ok) return res.json();
            else throw res.status;
        });
        const getGastos = resumenGastos(year).then(res => {
            if (res.ok) return res.json();
            else throw res.status;
        })

        Promise.all([getIngresos, getGastos]).then(response => {
            const resultado = {
                SumaImp1: response[0].value[response[0].value.length - 1].SumaImp1 - response[1].value[response[1].value.length - 1].SumaImp1,
                SumaImp2: response[0].value[response[0].value.length - 1].SumaImp2 - response[1].value[response[1].value.length - 1].SumaImp2,
                SumaImp3: response[0].value[response[0].value.length - 1].SumaImp3 - response[1].value[response[1].value.length - 1].SumaImp3,
                SumaImp4: response[0].value[response[0].value.length - 1].SumaImp4 - response[1].value[response[1].value.length - 1].SumaImp4,
            };
            resultado.SumaImpA = resultado.SumaImp1 + resultado.SumaImp2 + resultado.SumaImp3 + resultado.SumaImp4;
            this.setState({
                ingresos: response[0].value,
                gastos: response[1].value,
                resultado,
                year,
            });
            this.props.setLoader(false);
        }).catch(e => {
            if (e === 401) {
                this.context.logout();
            } else {
                this.setState({ error: true });
                this.props.setLoader(false);
            }
        });
    }

    getYears = () => {
        let currentYear = new Date().getFullYear(), years = [];
        const startYear = 2000;
        while (startYear <= currentYear) {
            years.push(currentYear--);
        }
        return years;
    }

    dropdownYearHandler = isHoverable => {
        if (isHoverable) {
            this.dropdownYear && (this.dropdownYear.className = 'dropdown is-active');
        } else {
            this.dropdownYear && (this.dropdownYear.className = 'dropdown');
        }
    }

    render() {
        if (this.state.ingresos.length === 0 && this.state.gastos.length === 0) {
            return this.state.error ? <div className="box has-background-danger-light has-text-danger">
                Ha ocurrido un error inesperado. Vuelva a intentarlo más tarde.
            </div> : null;
        }

        return (<>
            {this.state.error && <div className="box has-background-danger-light has-text-danger mb-0">
                Ha ocurrido un error inesperado. Vuelva a intentarlo más tarde.
            </div>}
            <div className="section">
                <div className="container">
                    <div className="nombreEmpresa fontTitle">{this.context.user.name.trim()}</div>
                    <div className="is-flex-desktop is-flex-direction-row">
                        <div className="is-flex is-flex-grow-1 is-align-items-center mr-6-desktop">Cuenta de Explotación. Desglose trimestres periodo: 01/01/{this.state.year} ~ 31/12/{this.state.year}</div>
                        <div className="is-flex-touch is-justify-content-flex-end">
                            <div className="dropdown" ref={ref => this.dropdownYear = ref} onMouseOut={this.dropdownYearHandler.bind(this, false)} onMouseOver={this.dropdownYearHandler.bind(this, true)}>
                                <div className="dropdown-trigger">
                                    <button className="button" aria-haspopup="true" aria-controls="dropdown-menu">
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar3" viewBox="0 0 16 16">
                                                <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                                                <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                            </svg>
                                        </span>
                                        <span>{this.state.year ?? 'Periodo'}</span> 
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                                <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659l4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                    <div className="dropdown-content scrollable">
                                        {this.getYears().map(year => {
                                            if (year === this.state.year) {
                                                return <a key={year.toString()} className="dropdown-item is-active">{year}</a>
                                            } else {
                                                return <a key={year.toString()} className="dropdown-item" onClick={this.loadData.bind(this, year)}>{year}</a>
                                            }
                                        })}
                                      </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-container mt-3">
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>DESCRIPCIÓN</th>
                                    <th align="center">T1</th>
                                    <th align="center">T2</th>
                                    <th align="center">T3</th>
                                    <th align="center">T4</th>
                                    <th align="center">EJERCICIO</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="tableTitle noHover">
                                    <td colSpan="6">INGRESOS</td>
                                </tr>
                                {this.state.ingresos.map((row, index) => {
                                    if (index === this.state.ingresos.length - 1) {
                                        return [
                                            <tr key={index + '-b'} className="noHover">
                                                <td colSpan="6"></td>
                                            </tr>,
                                            <tr key={index}>
                                                <td className="bold">TOTAL INGRESOS</td>
                                                <td align="end">{formatter(row.SumaImp1)}</td>
                                                <td align="end">{formatter(row.SumaImp2)}</td>
                                                <td align="end">{formatter(row.SumaImp3)}</td>
                                                <td align="end">{formatter(row.SumaImp4)}</td>
                                                <td align="end">{formatter(row.SumaImpA)}</td>
                                            </tr>
                                        ]
                                    }
                                    return <tr key={index}>
                                        <td>{ row.Titulo }</td>
                                        <td align="end">{formatter(row.SumaImp1) }</td>
                                        <td align="end">{formatter(row.SumaImp2) }</td>
                                        <td align="end">{formatter(row.SumaImp3) }</td>
                                        <td align="end">{formatter(row.SumaImp4) }</td>
                                        <td align="end">{formatter(row.SumaImpA) }</td> 
                                    </tr>
                                })}
                                <tr className="noHover">
                                    <td colSpan="6"></td>
                                </tr>
                                <tr className="tableTitle noHover">
                                    <td colSpan="6">GASTOS</td>
                                </tr>
                                {this.state.gastos.map((row, index) => {
                                    if (index === this.state.gastos.length - 1) {
                                        return [
                                            <tr key={index + '-b'} className="noHover">
                                                <td colSpan="6"></td>
                                            </tr>,
                                            <tr key={index}>
                                                <td className="bold">TOTAL GASTOS</td>
                                                <td align="end">{formatter(row.SumaImp1)}</td>
                                                <td align="end">{formatter(row.SumaImp2)}</td>
                                                <td align="end">{formatter(row.SumaImp3)}</td>
                                                <td align="end">{formatter(row.SumaImp4)}</td>
                                                <td align="end">{formatter(row.SumaImpA)}</td>
                                            </tr>
                                        ]
                                    }
                                    return <tr key={index}>
                                        <td>{row.Titulo}</td>
                                        <td align="end">{formatter(row.SumaImp1)}</td>
                                        <td align="end">{formatter(row.SumaImp2)}</td>
                                        <td align="end">{formatter(row.SumaImp3)}</td>
                                        <td align="end">{formatter(row.SumaImp4)}</td>
                                        <td align="end">{formatter(row.SumaImpA)}</td>
                                    </tr>
                                })}
                                <tr className="noHover">
                                    <td colSpan="6"></td>
                                </tr>
                                <tr className="final noHover">
                                    <td>RESULTADO</td>
                                    <td align="end">{formatter(this.state.resultado?.SumaImp1)}</td>
                                    <td align="end">{formatter(this.state.resultado?.SumaImp2)}</td>
                                    <td align="end">{formatter(this.state.resultado?.SumaImp3)}</td>
                                    <td align="end">{formatter(this.state.resultado?.SumaImp4)}</td>
                                    <td align="end">{formatter(this.state.resultado?.SumaImpA)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>);
    }
}
