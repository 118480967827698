import React, { PureComponent } from 'react';


export default class Footer extends PureComponent {
    static displayName = Footer.name;

    render() {
        const year = new Date().getFullYear();
        return (
            <footer className="footer">  
                <div className="content has-text-centered">
                    © Copyright {year} AFE CONSULTING • Todos los derechos reservados • <a href="https://afeconsulting.es/politica-de-privacidad/" target="_blank" rel="noopener noreferrer">Política de Privacidad</a> • <a href="https://afeconsulting.es/aviso-legal/" target="_blank" rel="noopener noreferrer">Aviso Legal</a> 
                </div>
            </footer>
        );
    }
}
