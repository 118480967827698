import { URL_PREFIX } from './data';

export function getUser() {
    return fetch(URL_PREFIX + 'Account');
}

export function login(data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    };

    return fetch(URL_PREFIX + 'Account/login', requestOptions);
}

export function logout() {
    const requestOptions = {
        method: 'POST',  
    };

    return fetch(URL_PREFIX + 'Account/logout', requestOptions);
}