import React, { PureComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import  Layout from './components/Layout';
import Login from './screens/Login';
import Resumen from './screens/Resumen';
import Ventas from './screens/Ventas';
import Compras from './screens/Compras';
import PrevisionPagos from './screens/PrevisionPagos';
import PrevisionCobros from './screens/PrevisionCobros';
import Documentos from './screens/Documentos';
import Loader from './components/Loader';
import { authContext } from './authContext';
import { getUser } from './api/auth';

import './App.scss';
import './custom.css';


export default class App extends PureComponent {
    static displayName = App.name;
    static contextType = authContext;

    constructor(props) {
        super(props);
        this.state = {
            loaderActive: true,
        }
    }

    componentDidMount() {
        getUser()
            .then(res => {
                if (res.ok) return res.json();
                else throw res.status;
            }).then(userResponse => {
                if (userResponse && userResponse.login != null && (userResponse.accessLevel === 1 || userResponse.accessLevel === 2)) {
                    this.context.setUser(userResponse);
                } else {
                    this.context.user != null && this.context.setUser(null);
                }
            }).catch(e => {
                this.context.user != null && this.context.setUser(null);
            });
    }

    setLoader = (value, force) => {
        if (this.force && !force) return; //si esta forzado y no viene pidiendo forzar, rechazamos
        this.force = force;
        this.setState({ loaderActive: value });
    }

    render() {
        const APP = this.context.user == null ?
            <Layout setLoader={this.setLoader}>
                <Switch>
                    <Route path='/'>
                        <Login setLoader={this.setLoader} />
                    </Route>
                </Switch>
            </Layout>

            : this.context.isAuthenticated() ?
                this.context.isAdministrator() ?
                    <Layout setLoader={this.setLoader}>
                        <Switch>
                            <Route path='/resumen' >
                                <Resumen setLoader={this.setLoader} />
                            </Route>
                            <Route path='/ventas' >
                                <Ventas setLoader={this.setLoader} />
                            </Route>
                            <Route path='/compras' >
                                <Compras setLoader={this.setLoader} />
                            </Route>
                            <Route path='/prevision-pagos' >
                                <PrevisionPagos setLoader={this.setLoader} />
                            </Route>
                            <Route path='/prevision-cobros' >
                                <PrevisionCobros setLoader={this.setLoader} />
                            </Route>
                            <Route path='/documentos' >
                                <Documentos setLoader={this.setLoader} />
                            </Route>
                            <Route path='/' render={() => <Redirect to='/resumen' />} />
                        </Switch>
                    </Layout>
                    :
                    <Layout setLoader={this.setLoader}>
                        <Switch>
                            <Route path='/ventas' >
                                <Ventas setLoader={this.setLoader} />
                            </Route>
                            <Route path='/compras' >
                                <Compras setLoader={this.setLoader} />
                            </Route>
                            <Route path='/prevision-pagos' >
                                <PrevisionPagos setLoader={this.setLoader} />
                            </Route>
                            <Route path='/prevision-cobros' >
                                <PrevisionCobros setLoader={this.setLoader} />
                            </Route>
                            <Route path='/documentos' >
                                <Documentos setLoader={this.setLoader}  />
                            </Route>
                            <Route path='/' render={() => <Redirect to='/ventas' />} />
                        </Switch>
                    </Layout>
            : null;

        return (
            <>
                { APP }
                <Loader active={this.state.loaderActive} />
            </>
        );
    }
}