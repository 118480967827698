
//utilizamos el locale de-DE (Alemania) porque fuerza el separador de miles con 4 digitos, al contrario que el es-ES
export const formatter = number => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(Math.abs(number) < 0.005 ? 0 : number);

export const dateToString = date => date ? new Date(date).toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '-';

export const getExportData = (title, subtitle, details, dataColumns, data) => {

    return data = [
        {
            columns: [
                { title: title || '', style: { font: { sz: "16", bold: true } } },
            ],
            data: [[{ value: '' }]]
        },
        {
            ySteps: -1,
            columns: [
                { title: subtitle || '' }
            ],
            data: [[{ value: '' }]]
        },
        {
            columns: [
                { title: details[0]?.label || '', style: { font: { bold: false } } },
                { title: details[0]?.description || '', style: { font: { bold: false } } },
                { title: details[0]?.code || '', style: { font: { bold: false } } },
            ],
            data: [[{ value: '' }]]
        },
        {
            ySteps: -1,
            columns: [
                { title: details[1]?.label || '', style: { font: { bold: false } } },
                { title: dateToString(details[1]?.start), style: { font: { bold: false } } },
                { title: dateToString(details[1]?.end), style: { font: { bold: false } } },
            ],
            data: [[{ value: '' }]]
        },
        {
            columns: dataColumns.map(title => {
                return {
                    title, style: { font: { bold: true }, fill: { patternType: "solid", fgColor: { rgb: "808080" } } } };
            }),

            data
        }
    ];
}
