import React, { PureComponent } from 'react';
import NavMenu from './NavMenu';
import Footer from './Footer';

export default class Layout extends PureComponent {
    static displayName = Layout.name;

    isIE = /*@cc_on!@*/false || !!document.documentMode;

    render() {
        return (
          <>
            <NavMenu setLoader={this.props.setLoader} />
             <main className={this.isIE ? '' : 'main'}>
                {this.props.children}
            </main>
            <Footer />
          </>
        );
  }
}
