import React, { PureComponent } from 'react';
import { authContext } from '../authContext';
import { formatter, dateToString, getExportData } from '../utils';
import { listaBancos } from '../api/bancos';
import { listaProveedores } from '../api/proveedores';
import { previsionPagos } from '../api/previsionPagos';
import Periodo from '../components/Periodo';
import { getPeriodo, getStartDate, getEndDate } from '../periodo';
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


export default class PrevisionPagos extends PureComponent {
    static displayName = PrevisionPagos.name;
    static contextType = authContext;

    constructor(props) {
        super(props);
        this.state = {
            previsionPagos: null
        }
        this.proveedores = null;
        this.bancos = null;
        this.props.setLoader(true);
        this.loadData();
    }

    getProveedores = this.proveedores != null ? Promise.resolve(this.proveedores) : listaProveedores().then(res => res.ok ? res.json() : Promise.reject(res.status));

    getBancos = this.bancos != null ? Promise.resolve(this.bancos) : listaBancos().then(res => res.ok ? res.json() : Promise.reject(res.status));

    loadData = event => {
        if (event) {
            event.persist();
            const entidadNombre = event.target.value;
            this.banco = this.bancos.find(banco => banco.entidadNombre.trim() === entidadNombre);
        }

        this.props.setLoader(true);

        Promise.all([this.getProveedores, this.getBancos]).then(values => {
            this.proveedores = values[0]?.value || [];
            this.bancos = values[1] || [];
            (this.bancos.length === 0 || this.bancos[0].cuenta !== '5700000000') && this.bancos.unshift({ banco: 0, cuenta: '5700000000', entidadNombre: 'CUENTA DE CAJA' });

            previsionPagos(this.banco?.cuenta, getPeriodo()).then(res => {
                if (res.ok) return res.json();
                else throw res.status;
            }).then(({ value }) => {
                if (value && value.length > 0) {
                    value.forEach(pago => {
                        //añadimos el nombre del proveedor ya que solo nos viene su id
                        pago.NombreComercial = this.proveedores.find(proveedor => proveedor.Proveedor === pago.Proveedor).NombreComercial.trim();
                    });
                }
                const data = this.getDataToExport(value);
                this.setState({ previsionPagos: value, data });
                this.props.setLoader(false);
            }).catch(e => {
                if (e === 401) {
                    this.context.logout();
                } else {
                    this.setState({ error: true });
                    this.props.setLoader(false);
                }
            });
        }).catch(e => {
            if (e === 401) {
                this.context.logout();
            } else {
                this.setState({ error: true });
                this.props.setLoader(false);
            }
        });
    }

    reset = () => {
        this.inputBanco && (this.inputBanco.value = '');
        this.banco = null;
        this.loadData();
    }

    getDataToExport = previsionPagos => {
        const details = [];
        details.push({
            label: 'Cuenta',
            description: this.banco ? this.banco.cuenta : 'Todas',
            code: this.banco?.entidadNombre.trim()
        });
        details.push({
            label: 'Periodo',
            start: getStartDate(),
            end: getEndDate()
        });
        const dataColumns = ['FECHA VTO.', 'PROVEEDOR', 'FACTURA', 'FECHA FAC.', 'PAGO', 'BANCO', 'IMPORTE'];
        let total = 0;
        const data = previsionPagos.map((row, index) => {
            total += row.Importe;
            let backgroundColor = 'FFFFFF';
            if (index % 2 !== 0) backgroundColor = 'e6e6e6';
            return [
                { value: dateToString(row.FechaPago), style: { fill: { patternType: "solid", fgColor: { rgb: backgroundColor } } } },
                { value: row.NombreComercial, style: { fill: { patternType: "solid", fgColor: { rgb: backgroundColor } } } },
                { value: row.Numero.toString(), style: { fill: { patternType: "solid", fgColor: { rgb: backgroundColor } }, alignment: { horizontal: 'right' } } },
                { value: dateToString(row.Fecha), style: { fill: { patternType: "solid", fgColor: { rgb: backgroundColor } } } },
                { value: row.NumPago + '/' + row.NumPagos, style: { fill: { patternType: "solid", fgColor: { rgb: backgroundColor } }, alignment: { horizontal: 'right' } } },
                { value: this.banco ? this.banco.entidadNombre.trim() : (this.bancos.find(banco => banco.cuenta === row.CtaPago)?.entidadNombre.trim() || ''), style: { fill: { patternType: "solid", fgColor: { rgb: backgroundColor } } } },
                { value: formatter(row.Importe), style: { fill: { patternType: "solid", fgColor: { rgb: backgroundColor } }, alignment: { horizontal: 'right' } } },
            ]
        });
        data.push([
            { value: 'TOTAL', style: { font: { bold: true }, fill: { patternType: "solid", fgColor: { rgb: "808080" } } } },
            { value: '', style: { font: { bold: true }, fill: { patternType: "solid", fgColor: { rgb: "808080" } } } },
            { value: '', style: { font: { bold: true }, fill: { patternType: "solid", fgColor: { rgb: "808080" } } } },
            { value: '', style: { font: { bold: true }, fill: { patternType: "solid", fgColor: { rgb: "808080" } } } },
            { value: '', style: { font: { bold: true }, fill: { patternType: "solid", fgColor: { rgb: "808080" } } } },
            { value: '', style: { font: { bold: true }, fill: { patternType: "solid", fgColor: { rgb: "808080" } } } },
            { value: formatter(total), style: { font: { bold: true }, fill: { patternType: "solid", fgColor: { rgb: "808080" } }, alignment: { horizontal: 'right' } } },
        ]);

        return getExportData(this.context.user.name.trim(), 'PREVISIÓN DE PAGOS', details, dataColumns, data);
    }

    render() {
        if (this.state.previsionPagos == null) {
            return this.state.error ? <div className="box has-background-danger-light has-text-danger">
                Ha ocurrido un error inesperado. Vuelva a intentarlo más tarde.
            </div> : null;
        }

        return (
            <div className="section">
                <div className="container">
                    <div className="nombreEmpresa fontTitle">{this.context.user.name.trim()}</div>
                    <div>
                        Banco
                        <div className="is-flex-desktop is-flex-grow-1 is-flex-direction-row is-align-items-center">
                            <div className="is-flex is-flex-grow-1 is-flex-direction-row is-align-items-center mb-3">
                                <input list="bancos" ref={ref => this.inputBanco = ref} className="input mr-4-desktop" name="bancos" type="text" onChange={this.loadData} placeholder="Escriba o seleccione un banco" />
                                {this.banco && <a title="Reestablecer selección" className="delete ml-3-mobile mr-5-desktop" onClick={this.reset}></a>}
                            </div>
                            <div className="is-flex is-justify-content-center mb-3">
                                <Periodo onChange={this.loadData} />
                            </div>
                        </div>
                        <datalist id="bancos">
                            {this.bancos.map(banco =>
                                <option key={banco.banco} value={banco.entidadNombre.trim()} />
                            )}
                        </datalist>
                    </div>
                    <div>
                        <div className="is-flex is-flex-direction-row is-align-items-center">
                            <div className="is-flex is-flex-grow-1 mr-1-mobile">Cuenta: {this.banco?.cuenta || 'Todos los bancos'}</div>
                            <ExcelFile filename="previsionPagos" element={
                                <button className="button" disabled={this.state.previsionPagos.length === 0} title="Descargar datos en formato Excel">
                                    <span className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
                                            <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z" />
                                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                        </svg>
                                    </span>
                                    <span>Exportar</span>
                                </button>
                            }>
                                <ExcelSheet dataSet={this.state.data} name="Previsión de pagos">
                                </ExcelSheet>
                            </ExcelFile>
                        </div>
                        <div className="table-container mt-3">
                            <table id="table" className="table is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>FECHA VTO.</th>
                                        <th>PROVEEDOR</th>
                                        <th align="end">FACTURA</th>
                                        <th align="end">FECHA FAC.</th>
                                        <th align="end">PAGO</th>
                                        <th>BANCO</th>
                                        <th align="end">IMPORTE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.previsionPagos.length === 0 ? <tr className="noHover"><td colSpan="7" className="text-nowrap">No hay datos.</td></tr>
                                        :
                                    this.state.previsionPagos.map(row => {
                                            return <tr key={row.IdReg}>
                                                <td>{dateToString(row.FechaPago)}</td>
                                                <td>{row.NombreComercial}</td>
                                                <td align="end">{row.Numero}</td>
                                                <td align="end">{dateToString(row.Fecha)}</td>
                                                <td align="end">{row.NumPago}/{row.NumPagos}</td>
                                                <td>{this.banco ? this.banco.entidadNombre.trim() : this.bancos.find(banco => banco.cuenta === row.CtaPago)?.entidadNombre.trim()}</td>
                                                <td align="end">{formatter(row.Importe)}</td>
                                            </tr>
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}
