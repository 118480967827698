import React, { createContext, useState } from 'react';
import { logout as logoutServer } from './api/auth';

export const authContext = createContext();

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

function useProvideAuth() {
    const [user, setUser] = useState(false); //valor por defecto false indicando que todavia no se ha cargado

    const isAuthenticated = () => user && user.login != null && (user.accessLevel === 1 || user.accessLevel === 2);

    const isAdministrator = () => user && user.accessLevel === 2;

    const logout = () => {
        logoutServer();
        setUser(null);
    }

    return {
        user,
        setUser,
        isAuthenticated,
        isAdministrator,
        logout
    };
}