import React, { PureComponent } from 'react';

export default class Loader extends PureComponent {
    static displayName = Loader.name;

    render() {
        const wrapperClassName = this.props.active ? 'loader-wrapper is-active' : 'loader-wrapper';
        return (
            <div className={wrapperClassName} >
                <div className="loader is-loading"></div>
            </div>
        );
    }
}
