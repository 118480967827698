import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { authContext } from '../authContext';
import { sendMail } from '../api/email';


export default class NavMenu extends PureComponent {
    static displayName = NavMenu.name;
    static contextType = authContext;

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        }
    }

    toggleBurger = () => this.setState({ menuOpen: !this.state.menuOpen });

    logoutHandler = () => {
        this.props.setLoader(true, true); //force
        this.context.logout();
        window.location.href = 'https://afeconsulting.es/';
    }

    dropdownMenuHandler = isHoverable => {
        if (isHoverable) {
            this.dropdownMenu && (this.dropdownMenu.className = 'navbar-item fontTitle has-dropdown is-active');
        } else {
            this.dropdownMenu && (this.dropdownMenu.className = 'navbar-item fontTitle has-dropdown');
        }
    }

    toggleModal = modalActive => {
        this.inputSubject.value = '';
        this.textarea.value = '';
        this.setState({ modalActive, menuOpen: false });
    }

    setEmail = event => this.email = event.target.value;
    setTel = event => this.tel = event.target.value;
    setSubject = event => this.subject = event.target.value;
    setBody = event => this.body= event.target.value;

    send = event => {
        event.preventDefault();
        this.props.setLoader(true);
        this.toggleModal(false);
        this.inputSubject.value = '';
        this.textarea.value = '';

        sendMail(this.email, this.tel, this.subject, this.body).then(res => {
            if (res.ok) {
                this.state.mailError && this.setState({ mailError: false });
                this.setState({ showNotification: true });
                setTimeout(() => this.setState({ showNotification: false }), 5000);
                this.props.setLoader(false);
            }
            else throw res.status;
        }).catch(e => {
            this.props.setLoader(false);
            this.setState({ mailError: true, showNotification: true });
        });
    }

    closeNotification = () => this.setState({ showNotification: false });

    render() {

        const menuItems = !this.context.isAuthenticated() ? null : this.context.isAdministrator() ?
            <div className="navbar-start" >
                <NavLink to='/resumen' className="navbar-item fontTitle minWidth96" activeClassName='is-active' onClick={() => this.state.menuOpen && this.setState({ menuOpen: false })}>
                    Resumen
                </NavLink >
                <NavLink to='/ventas' className="navbar-item fontTitle minWidth76" activeClassName='is-active' onClick={() => this.state.menuOpen && this.setState({ menuOpen: false })}>
                    Ventas
                </NavLink>
                <NavLink to='/compras' className="navbar-item fontTitle minWidth93" activeClassName='is-active' onClick={() => this.state.menuOpen && this.setState({ menuOpen: false })}>
                    Compras
                </NavLink>
                <div className="navbar-item fontTitle has-dropdown" ref={ref => this.dropdownMenu = ref} onMouseOut={this.dropdownMenuHandler.bind(this, false)} onMouseOver={this.dropdownMenuHandler.bind(this, true)}>
                    <div className="navbar-link">
                        Previsiones
                    </div>
                    <div className="navbar-dropdown is-boxed" onClick={this.dropdownMenuHandler.bind(this, false)}>
                        <NavLink to='/prevision-pagos' className="navbar-item fontTitle" activeClassName='is-active' onClick={() => this.state.menuOpen && this.setState({ menuOpen: false })}>
                            Previsión de pagos
                         </NavLink>
                        <NavLink to='/prevision-cobros' className="navbar-item fontTitle" activeClassName='is-active' onClick={() => this.state.menuOpen && this.setState({ menuOpen: false })}>
                            Previsión de cobros
                        </NavLink>
                    </div>
                </div>
                <NavLink to='/documentos' className="navbar-item fontTitle minWidth122" activeClassName='is-active' onClick={() => this.state.menuOpen && this.setState({ menuOpen: false })}>
                    Documentos
                </NavLink>
            </div>
            :
            <div className="navbar-start" >
                <NavLink to='/ventas' className="navbar-item fontTitle minWidth76" activeClassName='is-active' onClick={() => this.state.menuOpen && this.setState({ menuOpen: false })}>
                    Ventas
                </NavLink>
                <NavLink to='/compras' className="navbar-item fontTitle minWidth93" activeClassName='is-active' onClick={() => this.state.menuOpen && this.setState({ menuOpen: false })}>
                    Compras
                </NavLink>
                <div className="navbar-item fontTitle has-dropdown" ref={ref => this.dropdownMenu = ref} onMouseOut={this.dropdownMenuHandler.bind(this, false)} onMouseOver={this.dropdownMenuHandler.bind(this, true)}>
                    <div className="navbar-link">
                        Previsiones
                    </div>
                    <div className="navbar-dropdown is-boxed" onClick={this.dropdownMenuHandler.bind(this, false)}>
                        <NavLink to='/prevision-pagos' className="navbar-item fontTitle" activeClassName='is-active' onClick={() => this.state.menuOpen && this.setState({ menuOpen: false })}>
                            Previsión de pagos
                         </NavLink>
                        <NavLink to='/prevision-cobros' className="navbar-item fontTitle" activeClassName='is-active' onClick={() => this.state.menuOpen && this.setState({ menuOpen: false })}>
                            Previsión de cobros
                        </NavLink>
                    </div>
                </div>
                <NavLink to='/documentos' className="navbar-item fontTitle minWidth122" activeClassName='is-active' onClick={() => this.state.menuOpen && this.setState({ menuOpen: false })}>
                    Documentos
                </NavLink>
            </div>;


        return (
          <header>
                <nav className="navbar is-transparent">
                    <div className="container">
                        <div className="navbar-brand">
                            <a className="navbar-item" href="https://afeconsulting.es/" rel="noopener noreferrer">
                                <img src={process.env.PUBLIC_URL + '/LOGOTIPO-AFE-CONSULTING.svg'} alt="Logotipo de AFE Consulting" loading="lazy"/>
                            </a>
                            <div className={this.state.menuOpen ? "navbar-burger is-active" : "navbar-burger"} data-target="mainNavbar" onClick={this.toggleBurger}>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                            </div>
                         </div>
                        <div id="mainNavbar" className={ this.state.menuOpen ? "navbar-menu is-active" : "navbar-menu"}>

                            { menuItems }
                
                            <div className="navbar-end">
                                <div className="navbar-item">
                                    <div className="is-flex">
                                        <a className="is-flex is-align-items-center" target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=34601049801" title="Contactar vía WhatsApp">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                                                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                            </svg>
                                        </a>
                                        <a className="is-flex is-align-items-center ml-3" onClick={this.toggleModal.bind(this, true)} title="Contactar vía email">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
                                            </svg>
                                        </a>

                                        { this.context.isAuthenticated() &&
                                            <p className="control">
                                            <a className="button is-primary ml-3" onClick={this.logoutHandler}>
                                                    <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-box-arrow-left" viewBox="0 0 16 16">
                                                            <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z" />
                                                            <path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
                                                        </svg>
                                                    </span>
                                                    <span>Salir</span>
                                                </a>
                                            </p>
                                        }      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className={this.state.modalActive ? 'modal is-active' : 'modal'}>
                    <div className="modal-background" onClick={this.toggleModal.bind(this, false)}></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Contacto</p>
                            <button className="delete" aria-label="close" onClick={this.toggleModal.bind(this, false)}></button>
                        </header>
                        <form onSubmit={this.send}>
                            <section className="modal-card-body">
                                {!this.context.isAuthenticated() && <>
                                    <div className="field">
                                        <div className="control">
                                            Email
                                            <input className="input withBorder" name="email" type="text" onChange={this.setEmail} required="required"/>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="control">
                                            Teléfono
                                            <input className="input withBorder" type="text" onChange={this.setTel} />
                                        </div>
                                    </div>
                                </>}
                                <div className="field">
                                    <div className="control">
                                        Asunto
                                        <input className="input withBorder" type="text" name="subject" onChange={this.setSubject} required="required" ref={ref => this.inputSubject = ref} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <div className="control">
                                        <textarea className="textarea withBorder" name="message" placeholder="Contenido del mensaje" onChange={this.setBody} required="required" ref={ref => this.textarea = ref} required="required"></textarea>
                                    </div>
                                </div>
                            </section>
                            <footer className="modal-card-foot">
                                <button className="button" onClick={this.toggleModal.bind(this, false)}>Cancelar</button>
                                <button className="button is-primary" type="submit">Enviar</button>
                            </footer>
                        </form>
                    </div>
                </div>
                <div className={`notification notificationAbsolute ${this.state.mailError ? 'is-danger' : 'is-success'} ${this.state.showNotification ? '' : 'is-hidden'}`}>
                    <button className="delete" onClick={this.closeNotification}></button>
                        {this.state.mailError ? 'Ha ocurrido un error con el envío' : 'Mensaje enviado correctamente.'}
                    </div>
          </header>
        );
  }
}
