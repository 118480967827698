import { URL_PREFIX } from './data';

export function downloadDocument(idDocumento) {
    return fetch(URL_PREFIX + 'files/clients/' + idDocumento);
}

export function downloadVenta(idAsiento, tipo) {
    return fetch(URL_PREFIX + 'files/documents/' + idAsiento?.trim() + '/' + tipo + '/ventas');
}

export function downloadCompra(idAsiento, tipo) {
    return fetch(URL_PREFIX + 'files/documents/' + idAsiento?.trim() + '/' + tipo + '/compras');
}

export function downloadMulti(documents) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/zip',
        },
        body: JSON.stringify(documents)
    };

    return fetch(URL_PREFIX + 'files/multi', requestOptions);
}