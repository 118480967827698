import React, { PureComponent } from 'react';
import CookieConsent from "react-cookie-consent";
import { authContext } from '../authContext';
import { login } from '../api/auth';

export default class Login extends PureComponent {
    static displayName = Login.name;
    static contextType = authContext;

    constructor(props) {
        super(props);
        this.state = {
            error: false
        }
    }

    componentDidMount() {
        this.props.setLoader(false);
    }

    onFormSubmit = event => {
        event.preventDefault();
        this.props.setLoader(true);
        const data = {
            login: this.login,
            password: this.password
        }
        login(data)
            .then(res => {
                if (res.ok) return res.json();
                else throw res.status;
            }).then(loginResponse => {
                if (loginResponse && loginResponse.login != null && (loginResponse.accessLevel === 1 || loginResponse.accessLevel === 2)) {
                    this.context.setUser(loginResponse); //esto fuerza el refresco y ya la app entrará por el Router logeado
                } else throw loginResponse;
            }).catch(e => {
                this.inputPassword.value = '';
                this.props.setLoader(false);
                this.setState({ error: e === 401 ? 401 : 500 }); //error de password o de server
            });
    }

    setLogin = event => this.login = event.target.value;
    setPassword = event => this.password = event.target.value;


  render() {
      return (<>
          {
              this.state.error === 500 && <div className="box has-background-danger-light has-text-danger">
                  Ha ocurrido un error inesperado. Vuelva a intentarlo más tarde.
              </div>
          }
          <div className="section">
              <div className="container login">
                  <div className="columns is-centered">
                      <div className="column is-two-third">
                          <h1 className="title fontTitle">Acceda a la zona clientes</h1>
                          <h2 className="subtitle mt-6">Podrá consultar toda su información relacionada con los ámbitos; contable, fiscal, laboral y mercantil.</h2>
                      </div>

                      <div className="column is-one-third py-0">
                          <div className={`box has-background-danger-light has-text-danger p-2 mb-4 ${this.state.error === 401 ? '' : 'is-invisible'}`}>
                              Usuario o contraseña incorrectos.
                          </div>
                          <form onSubmit={this.onFormSubmit} autoComplete="on" target="iframe" action="about:blank">
                              <div className="field">
                                  <div className="control">
                                      <label htmlFor="inputUsuario" className="is-hidden">Usuario</label>
                                      <input className="input" id="inputUsuario" type="text" placeholder="Usuario" onChange={this.setLogin} required="required" />
                                  </div>
                              </div>
                              <div className="field">
                                  <div className="control">
                                      <label htmlFor="inputPassword" className="is-hidden">Contraseña</label>
                                      <input className="input" ref={ref => this.inputPassword = ref} id="inputPassword" type="password" placeholder="Contraseña" onChange={this.setPassword} required="required" />
                                  </div>
                              </div>
                              <div className="field">
                                  <div className="control has-text-centered">
                                      <button className="button is-primary" type="submit">Acceder</button>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
          <CookieConsent
              buttonText="Aceptar"
              buttonStyle={{ backgroundColor: '#3d3d3d', color: '#ffffff', borderRadius: 4,fontSize: '1rem'}}
              style={{ background: "#FFFFFF", color: 'rgb(51, 51, 51)', alignItems: 'center' }}
              expires={999}
              overlay>
              <div>Utilizamos cookies propias y de terceros para analizar nuestros servicios y mejorar su experiencia de usuario.</div>
              <div>Para más información o un cambio de configuración visite nuestra <a href="https://afeconsulting.es/politica-de-cookies/" target="_blank" rel="noopener noreferrer">política de cookies</a>.</div>
          </CookieConsent>
          <iframe name="iframe" src="about:blank" className="iframe" title="iframe"></iframe>
        </>);
  }
}
