import React, { PureComponent } from 'react';
import { getStartDate, getEndDate, setPeriodo } from '../periodo';
import { dateToString } from '../utils';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('es', es)


export default class Periodo extends PureComponent {
    static displayName = Periodo.name;

    constructor(props) {
        super(props);
        this.state = {
            startDate: getStartDate(),
            endDate: getEndDate(),
            year: null,
            semester: null,
            trimester: null,
            month: null
        }
    }

    toggleModal = isActive => {
        this.setState({
            modalActive: isActive,
        });
    }

    getYears = () => {
        if (this.years) return this.years;
        let currentYear = new Date().getFullYear(), years = [];
        const startYear = 2000;
        while (startYear <= currentYear) {
            years.push(currentYear--);
        }
        this.years = years;
        return years;
    }

    dropdownHandler = (dropdown, isHoverable) => {
        if (isHoverable) {
            dropdown && (dropdown.className = 'dropdown is-active');
        } else {
            dropdown && (dropdown.className = 'dropdown');
        }
    }

    getPeriodoString = () => dateToString(this.state.startDate) + ' - ' + dateToString(this.state.endDate);

    getTrimesterString = () => {
        switch (this.state.trimester) {
            case 0: return '1er Trimestre';
            case 1: return '2do Trimestre';
            case 2: return '3er Trimestre';
            case 3: return '4to Trimestre';
            default: return 'Trimestre';
        }
    }

    getMonthString = () => {
        switch (this.state.month) {
            case 0: return 'Enero';
            case 1: return 'Febrero';
            case 2: return 'Marzo';
            case 3: return 'Abril';
            case 4: return 'Mayo';
            case 5: return 'Junio';
            case 6: return 'Julio';
            case 7: return 'Agosto';
            case 8: return 'Septiembre';
            case 9: return 'Octubre';
            case 10: return 'Noviembre';
            case 11: return 'Diciembre';
            default: return 'Mes';
        }
    }

    setStartDate = date => this.setState({ startDate: date, year: null, semester: null, trimester: null, month: null });
    setEndDate = date => this.setState({ endDate: date, year: null, semester: null, trimester: null, month: null });

    setYear = year => {
        const start = new Date(year, 0, 1);
        const end = new Date(year + 1, 0, 1);
        end.setDate(end.getDate() - 1)
        end.setHours(23, 59, 59, 999);
        this.setState({ startDate: start, endDate: end, year, semester: null, trimester: null, month: null });
        this.dropdownHandler(this.dropdownYear, false);
    }

    setSemester = semester => {
        const year = this.state.year || new Date(this.state.endDate).getFullYear();
        const start = new Date(year, (6 * semester), 1);
        const end = new Date(year, 6 + (6 * semester), 1);
        end.setDate(end.getDate() - 1)
        end.setHours(23, 59, 59, 999);
        this.setState({ startDate: start, endDate: end, year, semester, trimester: null, month: null });
        this.dropdownHandler(this.dropdownSemester, false);
    }

    setTrimester = trimester => {
        const year = this.state.year || new Date(this.state.endDate).getFullYear();
        const start = new Date(year, (3 * trimester), 1);
        const end = new Date(year, 3 + (3 * trimester), 1);
        end.setDate(end.getDate() - 1)
        end.setHours(23, 59, 59, 999);
        this.setState({ startDate: start, endDate: end, year, semester: null, trimester, month: null });
        this.dropdownHandler(this.dropdownTrimester, false);
    }

    setMonth = month => {
        const year = this.state.year || new Date(this.state.endDate).getFullYear();
        const start = new Date(year, month, 1);
        const end = new Date(year, 1 + month, 1);
        end.setDate(end.getDate() - 1)
        end.setHours(23, 59, 59, 999);
        this.setState({ startDate: start, endDate: end, year, semester: null, trimester: null, month });
        this.dropdownHandler(this.dropdownMonth, false);
    }

    save = all => {
        let start = this.state.startDate;
        let end = this.state.endDate;

        if (all) {
            start = new Date(2000, 0, 1);
            end = new Date();
            end.setHours(23, 59, 59, 999);
            this.setState({ startDate: start, endDate: end, year: null, semester: null, trimester: null, month: null });
        }

        this.toggleModal(false);
        setPeriodo({ start, end });
        this.props.onChange && this.props.onChange();
    }

    cancel = () => {
        this.setState({ startDate: getStartDate(), endDate: getEndDate(), year: null, semester: null, trimester: null, month: null });
        this.toggleModal(false);
    }


    render() {
        return (
            <div>
                <button className="button" onClick={this.toggleModal.bind(this, true)}>
                    <span className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar3" viewBox="0 0 16 16">
                            <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                            <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                        </svg>
                    </span>
                    <span>{this.getPeriodoString()}</span>
                </button>
                <div className={this.state.modalActive ? 'modal is-active' : 'modal'}>
                    <div className="modal-background" onClick={this.cancel}></div>
                    <div className="modal-card overflowVisible">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Periodo</p>
                            <button className="delete" aria-label="close" onClick={this.cancel}></button>
                        </header>
                        <section className="modal-card-body overflowVisible">
                            <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center p-3">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    locale="es"
                                    closeOnScroll={true}
                                    selected={this.state.startDate}
                                    onChange={this.setStartDate}
                                    selectsStart
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    className="input textCentered withBorder"
                                />
                                <span className="mx-3">-</span>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    locale="es"
                                    closeOnScroll={true}
                                    selected={this.state.endDate}
                                    onChange={this.setEndDate}
                                    selectsEnd
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    minDate={this.state.startDate}
                                    className="input textCentered withBorder"
                                />
                            </div>
                            <div className="is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-center">
                                <div className="dropdown" ref={ref => this.dropdownYear = ref} onMouseOut={this.dropdownHandler.bind(this, this.dropdownYear, false)} onMouseOver={this.dropdownHandler.bind(this, this.dropdownYear, true)}>
                                    <div className="dropdown-trigger mr-3 pt-2">
                                        <button className="button minWidthEjercicio" aria-haspopup="true" aria-controls="dropdown-menu">
                                            <span>{this.state.year ?? 'Ejercicio'}</span>
                                            <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659l4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                        <div className="dropdown-content scrollable">
                                            {this.getYears().map(year => <a key={year.toString()} className={this.state.year === year ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setYear.bind(this, year)}>{ year }</a> )}
                                        </div>
                                    </div>
                                </div>

                                <div className="dropdown" ref={ref => this.dropdownSemester = ref} onMouseOut={this.dropdownHandler.bind(this, this.dropdownSemester, false)} onMouseOver={this.dropdownHandler.bind(this, this.dropdownSemester, true)}>
                                    <div className="dropdown-trigger mr-3 pt-2">
                                        <button className="button minWidthSemestre" aria-haspopup="true" aria-controls="dropdown-menu">
                                            <span>{this.state.semester != null ? this.state.semester === 0 ? '1er Semestre' : '2do Semestre' : 'Semestre'}</span>
                                            <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659l4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                        <div className="dropdown-content scrollable">
                                            <a className={this.state.semester === 0 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setSemester.bind(this, 0)}>Primero</a>
                                            <a className={this.state.semester === 1 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setSemester.bind(this, 1)}>Segundo</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="dropdown" ref={ref => this.dropdownTrimester = ref} onMouseOut={this.dropdownHandler.bind(this, this.dropdownTrimester, false)} onMouseOver={this.dropdownHandler.bind(this, this.dropdownTrimester, true)}>
                                    <div className="dropdown-trigger mr-3 pt-2">
                                        <button className="button minWidthTrimestre" aria-haspopup="true" aria-controls="dropdown-menu">
                                            <span>{this.getTrimesterString()}</span>
                                            <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659l4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                        <div className="dropdown-content scrollable">
                                            <a className={this.state.trimester === 0 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setTrimester.bind(this, 0)}>Primero</a>
                                            <a className={this.state.trimester === 1 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setTrimester.bind(this, 1)}>Segundo</a>
                                            <a className={this.state.trimester === 2 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setTrimester.bind(this, 2)}>Tercero</a>
                                            <a className={this.state.trimester === 3 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setTrimester.bind(this, 3)}>Cuarto</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="dropdown" ref={ref => this.dropdownMonth = ref} onMouseOut={this.dropdownHandler.bind(this, this.dropdownMonth, false)} onMouseOver={this.dropdownHandler.bind(this, this.dropdownMonth, true)}>
                                    <div className="dropdown-trigger pt-2">
                                        <button className="button minWidthMes" aria-haspopup="true" aria-controls="dropdown-menu">
                                            <span>{this.getMonthString()}</span>
                                            <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659l4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                        <div className="dropdown-content scrollable">
                                            <a className={this.state.month === 0 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setMonth.bind(this, 0)}>Enero</a>
                                            <a className={this.state.month === 1 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setMonth.bind(this, 1)}>Febrero</a>
                                            <a className={this.state.month === 2 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setMonth.bind(this, 2)}>Marzo</a>
                                            <a className={this.state.month === 3 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setMonth.bind(this, 3)}>Abril</a>
                                            <a className={this.state.month === 4 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setMonth.bind(this, 4)}>Mayo</a>
                                            <a className={this.state.month === 5 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setMonth.bind(this, 5)}>Junio</a>
                                            <a className={this.state.month === 6 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setMonth.bind(this, 6)}>Julio</a>
                                            <a className={this.state.month === 7 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setMonth.bind(this, 7)}>Agosto</a>
                                            <a className={this.state.month === 8 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setMonth.bind(this, 8)}>Septiembre</a>
                                            <a className={this.state.month === 9 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setMonth.bind(this, 9)}>Octubre</a>
                                            <a className={this.state.month === 10 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setMonth.bind(this, 10)}>Noviembre</a>
                                            <a className={this.state.month === 11 ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.setMonth.bind(this, 11)}>Diciembre</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="is-flex is-align-items-center is-justify-content-center pt-5">
                                <button className="button" onClick={this.save.bind(this, true)}><b>Todos los ejercicos</b></button>
                            </div>
                         </section>
                        <footer className="modal-card-foot">
                            <button className="button" onClick={this.cancel}>Cancelar</button>
                            <button className="button is-primary" onClick={this.save.bind(this, false)}>Guardar</button>
                        </footer>
                    </div>
                </div>
                
             </div>
        );
    }
}