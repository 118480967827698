import { URL_PREFIX } from './data';

export function sendMail(from, phone, subject, body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ from, phone, subject, body })
    };

    return fetch(URL_PREFIX + 'email/send', requestOptions);
}