import { URL_PREFIX } from './data';

export function compras(proveedor, periodo, punteo) {
    let query = '';

    if (proveedor) {
        query += query ? " and Cuenta eq '" + proveedor + "'" : "Cuenta eq '" + proveedor + "'";
    }
    if (periodo?.start) {
        query += query ? ' and Fecha ge ' + periodo.start.toISOString() : 'Fecha ge ' + periodo.start.toISOString();
    }
    if (periodo?.end) {
        query += query ? ' and Fecha lt ' + periodo.end.toISOString() : 'Fecha lt ' + periodo.end.toISOString();
    }
    if (punteo === false) {
        query += query ? " and (Punteo eq '0' or Punteo eq null)" : "(Punteo eq '0' or Punteo eq null)";
    } else if (punteo === true) {
        query += query ? " and (Punteo eq '1' or Punteo eq '2')" : "(Punteo eq '1' or Punteo eq '2')";
    }

    return fetch(URL_PREFIX + 'VSResumenCompras?$orderby=Fecha asc&' + (query ? '$filter=' + query : ''));
}