import React, { PureComponent } from 'react';
import { authContext } from '../authContext';
import { dateToString } from '../utils';
import { documentos, listaTipos } from '../api/documentos';
import { downloadDocument, downloadMulti } from '../api/files';
import Periodo from '../components/Periodo';
import { getPeriodo } from '../periodo';


export default class Documentos extends PureComponent {
    static displayName = Documentos.name;
    static contextType = authContext;

    constructor(props) {
        super(props);
        this.state = {
            documentos: null,
            selected: []
        }
        this.tipos = null;
        this.tipo = null;
        this.descripcion = null;
        this.loadDocumentos();
    }

    getTipos = this.tipos != null ? Promise.resolve(this.tipos) : listaTipos().then(res => res.ok ? res.json() : Promise.reject(res.status));

    getDocumentos = () => documentos(this.tipo, getPeriodo(), this.descripcion).then(res => res.ok ? res.json() : Promise.reject(res.status));

    loadDocumentos = event => {
        if (event) {
            event.persist();
            this.descripcion = event.target.value;
        }

        this.props.setLoader(true);

        Promise.all([this.getTipos, this.getDocumentos()]).then(values => {
            this.tipos = values[0];
            this.setState({ documentos: values[1].value, selected: [] });
            this.props.setLoader(false);
        }).catch(e => {
            if (e === 401) {
                this.context.logout();
            } else {
                this.setState({ error: true });
                this.props.setLoader(false);
            }
        });
  
    }

    loadDebounced = event => {
        event.persist();
        this.descripcion = event.target.value;
        clearTimeout(this.timeoutLoad);
        this.timeoutLoad = setTimeout(this.loadDocumentos, 250);
    }

    dropdownTypeHandler = isHoverable => {
        if (isHoverable) {
            this.dropdownType && (this.dropdownType.className = 'dropdown is-active');
        } else {
            this.dropdownType && (this.dropdownType.className = 'dropdown');
        }
    }

    onClickTypeHandler = type => {
        this.tipo = type;
        this.dropdownTypeHandler(false);
        this.loadDocumentos();
    }

    toggleSelectedDocument = (idDocumento, selected) => {
        if (selected) {
            this.setState({ selected: this.state.selected.filter(item => item !== idDocumento) });
        } else {
            this.setState({ selected: [...this.state.selected, idDocumento] });
        }
    }

    toggleSelectAll = () => {
        if (this.state.selected.length === this.state.documentos?.length) {
            this.setState({ selected: [] });
        } else {
            const selected = [];
            this.state.documentos.forEach(documento => selected.push(documento.IdDocumento));
            this.setState({ selected });
        }
    }


    onClickDownload = () => {
        if (!this.state.selected && this.state.selected.length === 0) return;
        this.props.setLoader(true);
        if (this.state.selected.length === 1) {
            downloadDocument(this.state.selected).then(res => {
                if (res.ok) return res.blob();
                else throw res.status;
            }).then(blob => {
                const filename = this.state.documentos.find(documento => documento.IdDocumento === this.state.selected[0]).Descripcion.trim();
                if (blob && navigator?.msSaveBlob) {
                    navigator.msSaveBlob(blob, filename);
                    this.props.setLoader(false);
                    return;
                }
                const elm = document.createElement('a');
                elm.href = URL.createObjectURL(blob);
                elm.setAttribute('download', filename + '.pdf');
                document.body.append(elm);
                elm.click();
                elm.remove();
                URL.revokeObjectURL(elm.href);
                this.props.setLoader(false);
            }).catch(e => {
                if (e === 401) {
                    this.context.logout();
                } else {
                    this.setState({ error: true });
                    this.props.setLoader(false);
                }
            });
        } else {
            downloadMulti(this.state.selected).then(res => {
                if (res.ok) return res.blob();
                else throw res.status;
            }).then(blob => {
                if (blob && navigator?.msSaveBlob) {
                    navigator.msSaveBlob(blob, 'documentos.zip');
                    this.props.setLoader(false);
                    return;
                }
                const elm = document.createElement('a');
                elm.href = URL.createObjectURL(blob);
                elm.setAttribute('download', 'documentos.zip');
                document.body.append(elm);
                elm.click();
                elm.remove();
                URL.revokeObjectURL(elm.href);
                this.props.setLoader(false);
            }).catch(e => {
                if (e === 401) {
                    this.context.logout();
                } else {
                    this.setState({ error: true });
                    this.props.setLoader(false);
                }
            });
        }
        
    }

    render() {
        if (this.state.documentos == null) {
            return this.state.error ? <div className="box has-background-danger-light has-text-danger">
                Ha ocurrido un error inesperado. Vuelva a intentarlo más tarde.
            </div> : null;
        }

        return (<>
            {this.state.error && <div className="box has-background-danger-light has-text-danger mb-0">
                Ha ocurrido un error inesperado. Vuelva a intentarlo más tarde.
            </div>}
            <div className="section">
                <div className="container">
                    <div className="nombreEmpresa fontTitle">{this.context.user.name.trim()}</div>
                    <div>
                        Descripción
                        <div className="is-flex-desktop is-flex-grow-1 is-flex-direction-row is-align-items-center">
                            <div className="is-flex is-flex-grow-1 is-flex-direction-row is-align-items-center mb-3">
                                <input className="input mr-4-desktop" type="search" onChange={this.loadDebounced} placeholder="Filtre por descripción" />
                            </div>
                            <div className="is-flex is-justify-content-center mb-3">
                                <Periodo onChange={this.loadDocumentos} />
                            </div>
                        </div>
                    </div>
                    <div className="is-flex is-flex-direction-row is-align-items-center is-flex-wrap-wrap ">
                        <div className="is-flex is-flex-grow-1">
                            <div className="dropdown" ref={ref => this.dropdownType = ref} onMouseOut={this.dropdownTypeHandler.bind(this, false)} onMouseOver={this.dropdownTypeHandler.bind(this, true)}>
                                <div className="dropdown-trigger mb-3">
                                    <button className="button" aria-haspopup="true" aria-controls="dropdown-menu">
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tag" viewBox="0 0 16 16">
                                                <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
                                                <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586l7 7L13.586 9l-7-7H2v4.586z" />
                                            </svg>
                                        </span>
                                        <span>Tipo: {this.tipo ?? 'Todos'}</span>
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                                <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659l4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                    <div className="dropdown-content">                
                                        <a className={this.tipo == null ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.onClickTypeHandler.bind(this, null)}>Todos</a>
                                        {this.tipos?.map(({ tipo }) => 
                                            <a key={tipo} className={this.tipo === tipo ? 'dropdown-item is-active' : 'dropdown-item'} onClick={this.onClickTypeHandler.bind(this, tipo)}>{tipo}</a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="button mb-3" title="Descargar los documentos seleccionados" disabled={!this.state.selected || this.state.selected.length === 0} onClick={this.onClickDownload}>
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
                                    <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z" />
                                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                </svg>
                            </span>
                            <span>Descargar</span>
                        </button>
                    </div>
                    {this.state.documentos != null &&
                        <div>
                            <div className="table-container">
                                <table className="table is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input type="checkbox" checked={this.state.documentos?.length && this.state.selected.length === this.state.documentos?.length} onChange={this.toggleSelectAll} />
                                            </th>
                                            <th>DESCRIPCIÓN</th>
                                            <th align="center">FECHA</th>
                                            <th>TIPO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.documentos.length === 0 ? <tr className="noHover"><td colSpan="4" className="text-nowrap">No hay documentos.</td></tr>
                                            :
                                            this.state.documentos.map(row => {
                                                const selected = this.state.selected.includes(row.IdDocumento);

                                                return <tr key={row.IdDocumento} className={selected ? 'selectedDocument' : ''} onClick={this.toggleSelectedDocument.bind(this, row.IdDocumento, selected)} >
                                                    <td><input type="checkbox" checked={selected} onChange={this.toggleSelectedDocument.bind(this, row.IdDocumento, selected)} /></td>
                                                    <td>{row.Descripcion.trim()}</td>
                                                    <td align="center">{dateToString(row.FechaRegistro)}</td>
                                                    <td>{row.Tipo.trim()}</td>
                                                </tr>
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>);
    }
}
